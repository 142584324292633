<template>
    <div class="door_person-content">
        <div class="door_deviec-tabler">
            <el-select style="margin-left: 10px;" v-model="personGroupValue" placeholder="请选择"
                       @change="selecPersonGroupChange">
                <el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-input v-model="likeModel" placeholder="姓名进行模糊搜索" style="width: 150px;margin-left: 10px"
                      @input="likeInput"></el-input>

            <el-input v-model="likeIdCardModel" placeholder="身份证进行模糊搜索" style="width: 180px;margin-left: 10px"
                      @input="likeIdCardInput"></el-input>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="addPersonGroupDialogVisible = true">增加分组
            </el-button>

            <el-button size="mini" v-if="personGroupData.length>1?selectPersonArray.length<1:false" type="danger"
                       style="margin-left: 20px;" @click="deletePersonGroup">删除分组
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="updatePersonGroupDialogVisible = true">修改分组名称
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="addPersonButtonClick">增加人员
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="importPersonDialogVisible = true">导入人员
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="exportPersonInfo">导出人员信息
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="downLoadPersonPhoto">导出人员图片
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length>0" type="danger" style="margin-left: 20px;"
                       @click="deleteSelectPerson">删除选中人员
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length>0" type="warning" style="margin-left: 20px;"
                       @click="selectUpdatePersonGroupDialogVisible=true">修改人员分组
            </el-button>

            <el-button size="mini" v-if="selectPersonArray.length<1" type="primary" style="margin-left: 20px;"
                       @click="refreshPerson">刷新
            </el-button>
        </div>
        <div class="door_person-data-content">
            <el-table ref="filterTable" :data="personData" height="100%" style="width: 100%;" v-loading="loading"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column align="center" sortable prop="index" label="序号" width="100">
                </el-table-column>
                <el-table-column align="center" prop="fileUrl" label="照片" width="auto">
                    <template scope="scope">
                        <el-image style="width: 80px;height: 80px;" :src="scope.row.fileUrl"
                                  @click="showImage(scope.row.fileUrl)"/>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="姓名" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="idCard" label="身份证" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="" label="其它" width="auto">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>IC卡: {{ scope.row.icCard }}</p>
                            <p>手机号: {{ scope.row.phone }}</p>
                            <p>描述: {{ scope.row.description}}</p>
                            <div slot="reference" class="name-wrapper">
                                <el-tag size="medium">查看</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column align="center" prop="type" label="人员类型" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{getPersonType(scope.row.type)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="auto">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>
            <el-pagination style="margin-top: 20px;margin-bottom: 20px;" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="page"
                           :page-sizes="[10, 100, 500, 1000, 2000,3000]"
                           :page-size="length" layout="total, sizes, prev, pager, next, jumper" :total="personCount">
            </el-pagination>
        </div>

        <el-dialog title="增加人员分组" :visible.sync="addPersonGroupDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogAddPersonGroupName" maxlength="20" type="text"
                          show-word-limit>
				</el-input>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addPersonGroup">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="修改人员分组" :visible.sync="updatePersonGroupDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-input placeholder="名称" v-model="dialogUpdatePersonGroupName" maxlength="20" type="text"
                          show-word-limit>
				</el-input>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updatePersonGroup">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="增加人员" :visible.sync="addPersonDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<div style="width: 100%;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
					<el-upload class="avatar-uploader"
                               :action="axios.defaults.baseURL+'/doorPerson/uploadPersonRePhoto'"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccess"
                               :headers="headers"
                               :before-upload="beforeAvatarUpload">
						<img v-if="dialogPersonFileUrl" :src="dialogPersonFileUrl" class="avatar">
						<div v-else
                             style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 200px;height: 200px;">
							<el-button>添加照片</el-button>
						</div>

					</el-upload>
				</div>
				<el-input placeholder="名称" v-model="dialogPersonName" maxlength="20" type="text"
                          show-word-limit></el-input>
				<el-input style="margin-top: 10px;" placeholder="身份证号" maxlength="32" type="text" show-word-limit
                          v-model="dialogPersonIdCard"></el-input>
				<el-input style="margin-top: 10px;" placeholder="IC卡号" maxlength="15" type="text" show-word-limit
                          v-model="dialogPersonIcCard"></el-input>
				<el-input style="margin-top: 10px;" placeholder="手机号" maxlength="11" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="dialogPersonPhone"></el-input>
                <el-input style="margin-top: 10px;" placeholder="箱号" maxlength="8" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="dialogPersonBoxNumber"></el-input>
				<el-radio-group v-model="dialogPersonType" style="margin-top: 20px;">
					<el-radio :label="0">大众型</el-radio>
					<el-radio :label="1">私有型</el-radio>
					<el-radio :label="2">管理员</el-radio>
				</el-radio-group>

				<el-input style="margin-top: 10px;" placeholder="描述" maxlength="30" type="text" show-word-limit
                          v-model="dialogPersonDescription"></el-input>
				<el-select style="margin-top: 10px;width: 100%;" v-model="personGroupValue" placeholder="请选择"
                           @change="addPersonSelectGroupChange">
					<el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="readIdCard">读取身份证</el-button>
				<el-button type="primary" @click="addPerson">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="导入人员" :visible.sync="importPersonDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <h4 style="color: #f6092f">请添加一个人员后，再导出人员信息形成导入模板!</h4>
            <span>
				<el-upload class="upload-demo"
                           :action="axios.defaults.baseURL+'/doorPerson/uploadPersonInfo'"
                           :on-success="importPerson"
                           :headers="headers"
                           :show-file-list="false">
					<el-button size="small" type="primary">点击上传人员xls文件</el-button>
					<div slot="tip" class="el-upload__tip">只能上传xls文件，且不超过500kb</div>
				</el-upload>
			</span>
            <div style="margin-top: 40px;"></div>
            <span>
				<el-upload class="upload-demo"
                           :action="axios.defaults.baseURL+'/doorPerson/uploadPersonPhoto'"
                           multiple
                           :on-success="importPhoto"
                           :headers="headers"
                           :show-file-list="false">
					<el-button size="small" type="primary">点击上传人员图片</el-button>
					<div slot="tip" class="el-upload__tip">只能上传jpg文件，且不超过500kb</div>
				</el-upload>
			</span>

        </el-dialog>


        <el-dialog title="修改人员" :visible.sync="updatePersonDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<div style="width: 100%;margin-bottom: 10px;display: flex;flex-direction: row;justify-content: center;align-items: center;">
					<el-upload class="avatar-uploader"
                               :action="axios.defaults.baseURL+'/doorPerson/uploadPersonRePhoto'"
                               :show-file-list="false"
                               :on-success="handleAvatarSuccess"
                               :headers="headers"
                               :before-upload="beforeAvatarUpload">
						<img v-if="dialogPersonFileUrl" :src="dialogPersonFileUrl" class="avatar">
						<div v-else
                             style="display: flex;flex-direction: column;justify-content: center;align-items: center;width: 200px;height: 200px;">
							<el-button>添加照片</el-button>
						</div>

					</el-upload>
				</div>
				<el-input placeholder="名称" v-model="dialogPersonName" maxlength="20" type="text"
                          show-word-limit></el-input>
				<el-input style="margin-top: 10px;" placeholder="身份证号" maxlength="32" type="text" show-word-limit
                          v-model="dialogPersonIdCard"></el-input>
				<el-input style="margin-top: 10px;" placeholder="IC卡号" maxlength="15" type="text" show-word-limit
                          v-model="dialogPersonIcCard"></el-input>
				<el-input style="margin-top: 10px;" placeholder="手机号" maxlength="11" type="text" show-word-limit
                          onkeyup="value=value.replace(/[^\d]/g,'')" v-model="dialogPersonPhone"></el-input>
				<el-radio-group v-model="dialogPersonType" style="margin-top: 20px;">
					<el-radio :label="0">大众型</el-radio>
					<el-radio :label="1">私有型</el-radio>
					<el-radio :label="2">管理员</el-radio>
				</el-radio-group>
				<el-input style="margin-top: 10px;" placeholder="描述" maxlength="30" type="text" show-word-limit
                          v-model="dialogPersonDescription"></el-input>
				<el-select style="margin-top: 10px;width: 100%;" v-model="personGroupValue" placeholder="请选择"
                           @change="updatePersonSelectGroupChange">
					<el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="readIdCard">读取身份证</el-button>
				<el-button type="primary" @click="updatePerson">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-image :src="dialogPersonFileUrl"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog title="选择修改人员分组" :visible.sync="selectUpdatePersonGroupDialogVisible" width="20%"
                   :before-close="handleClose" :modal-append-to-body="false">
            <el-select v-model="personGroupValue" placeholder="请选择" @change="updatePersonSelectGroupChange">
                <el-option v-for="item in personGroupData" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
            </el-select>

            <el-button style="margin-left: 20px" type="primary" @click="updateSelectPersonToGroup">确定</el-button>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                personGroupValue: '',
                personGroupData: [],
                personData: [],
                loading: false,
                personCount: 0,
                personGroupId: 0,
                page: 1,
                length: 10,
                updatePersonDialogVisible: false,
                showImageDialogVisible: false,
                addPersonGroupDialogVisible: false,
                updatePersonGroupDialogVisible: false,
                addPersonDialogVisible: false,
                importPersonDialogVisible: false,
                exportPersonDialogVisible: false,
                selectUpdatePersonGroupDialogVisible: false,
                dialogPersonId: 0,
                dialogPersonType: 1,
                dialogPersonName: '',
                dialogPersonIdCard: '',
                dialogPersonIcCard: '',
                dialogPersonPhone: '',
                dialogPersonBoxNumber:'',
                dialogPersonDescription: '',
                dialogPersonFileUrl: '',
                dialogPersonFileName: '',
                dialogAddPersonGroupName: '',
                dialogUpdatePersonGroupName: '',
                selectPersonArray: [],
                fileList: [],
                likeModel: '',
                likeIdCardModel: '',
                headers: {
                    token: localStorage.getItem('token')
                }
            };
        },
        mounted() {
            this.refreshPerson()
        },
        methods: {
            likeInput(value) {
                this.refreshPerson()
            },
            likeIdCardInput(value) {
                this.refreshPerson()
            },
            handleSizeChange(length) {
                this.length = length
                this.getPersonList()
            },
            handleCurrentChange(page) {
                this.page = page
                this.getPersonList()
            },
            handleSelectionChange(selectPersonArray) {
                this.selectPersonArray = selectPersonArray
            },
            addPersonButtonClick(){
                this.clearData()
                this.addPersonDialogVisible = true
            },
            selecPersonGroupChange(personGroupValue) {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroupValue === personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                        this.getPersonList()
                    }
                }
            },
            addPersonSelectGroupChange(personGroupValue) {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroupValue === personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                    }
                }
            },
            updatePersonSelectGroupChange(personGroupValue) {
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroupValue === personGroup.name) {
                        this.personGroupId = personGroup.personGroupId
                    }
                }
            },
            getPersonGroupList() {
                setTimeout(() => {
                    axios.post('/doorPersonGroup/pagingQuery', {
                        page: 1,
                        length: 100
                    })
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.personGroupData = res.data.data.content
                                this.personGroupValue = this.personGroupData[this.personGroupData.length - 1].name
                                this.personGroupId = this.personGroupData[this.personGroupData.length - 1].personGroupId
                                this.getPersonList()
                            }
                        })
                })
            },
            addPersonGroup() {
                setTimeout(() => {
                    axios.post('/doorPersonGroup/add', {
                        name: this.dialogAddPersonGroupName,
                    })
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.clearData()
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.refreshPerson()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg
                                });
                            }
                            this.addPersonGroupDialogVisible = false
                        })
                })
            },
            updatePersonGroup() {
                setTimeout(() => {
                    axios.post('/doorPersonGroup/update', {
                        id: this.personGroupId,
                        name: this.dialogUpdatePersonGroupName,
                    })
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.value = this.dialogUpdatePersonGroupName
                                this.clearData()
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.refreshPerson()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.msg
                                });
                            }
                            this.updatePersonGroupDialogVisible = false
                        })
                })
            },
            deletePersonGroup() {
                this.$confirm('此操作将永久删除当前人员分组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    setTimeout((res) => {
                        axios.get('/doorPersonGroup/delete?id=' + this.personGroupId)
                            .then((res) => {
                                if (res.data.code === 1) {
                                    this.$message({
                                        type: 'success',
                                        message: res.data.msg
                                    });
                                    this.refreshPerson()
                                } else {
                                    this.$message({
                                        type: 'error',
                                        message: response.data.msg
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败!'
                                });
                            })
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            getPersonList() {
                this.personData = []
                for (var i = 0; i < this.personGroupData.length; i++) {
                    var personGroup = this.personGroupData[i]
                    if (personGroup.id = this.personGroupId) {
                        this.dialogUpdatePersonGroupName = personGroup.name
                    }
                }
                this.loading = true
                setTimeout(() => {
                    axios.post('/doorPerson/pagingQuery', {
                        page: this.page,
                        length: this.length,
                        parameter: {
                            doorPersonGroup: this.personGroupId
                        },
                        likeParameter: {
                            name: this.likeModel,
                            idCard: this.likeIdCardModel
                        }
                    })
                        .then((res) => {
                            this.loading = false
                            if (res.data.code === 1) {
                                this.personData = res.data.data.content
                                this.personCount = res.data.data.totalElements
                            } else {
                                this.personData = []
                                this.personCount = 0
                            }
                        })
                        .catch((error) => {
                            this.loading = false
                        })
                })
            },
            deletePerson(id) {
                setTimeout(() => {
                    axios.get('/doorPerson/delete?id=' + id)
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.msg
                                });
                                this.getPersonList(this.page, this.personGroupId)
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.data.msg
                                });
                            }
                        })
                })
            },
            errorHandler() {
                return true
            },

            handleEdit(index) {
                this.clearData()
                this.updatePersonDialogVisible = true
                var person = this.personData[index]
                this.dialogPersonId = person.id
                this.dialogPersonName = person.name
                this.dialogPersonIdCard = person.idCard
                this.dialogPersonIcCard = person.icCard
                this.dialogPersonPhone = person.phone
                this.dialogPersonDescription = person.description
                this.dialogPersonFileUrl = person.fileUrl
                this.dialogPersonFileName = person.fileName
                this.dialogPersonType = person.type
            },
            handleDelete(index) {
                var person = this.personData[index]
                this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    this.deletePerson(person.id)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            clearData() {
                this.dialogPersonId = ''
                this.dialogPersonName = ''
                this.dialogPersonIdCard = ''
                this.dialogPersonIcCard = ''
                this.dialogPersonPhone = ''
                this.dialogPersonDescription = ''
                this.dialogPersonFileName = ''
                this.dialogPersonFileUrl = ''
                this.dialogPersonType = 0
                console.log('clearData')
            },
            readIdCard() {
                setTimeout(() => {
                    axios.get('/idCard/read')
                        .then((res) => {
                            if (res.data.code===1) {
                                const  idCard=this.transformJson(res.data.data).Certificate
                                this.dialogPersonName=idCard.Name
                                this.dialogPersonIdCard=idCard.IDNumber
                                this.dialogPersonFileUrl='data:image/jpg;base64,'+idCard.Base64Photo
                                this.updateBase64(idCard.Base64Photo)
                            }else {
                                this.$message({
                                    type: 'error',
                                    message: '读取失败!'
                                });
                            }
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message: '读取失败!'
                            });
                        })
                })
            },
            transformJson(values) {
                let replace = values.replace(/\\/g, "/");
                return JSON.parse(replace)
            },
            updateBase64(base64){
                setTimeout(()=>{
                    axios.post('/doorPerson/updatePersonBase64',{
                        base64:base64
                    })
                        .then((res)=>{
                            if (res.data.code===1){
                                this.dialogPersonFileName=res.data.data
                            }
                        })
                })
            },
            addPerson() {
                setTimeout(() => {
                    axios.post('/doorPerson/add', {
                        name: this.dialogPersonName,
                        idCard: this.dialogPersonIdCard,
                        icCard: this.dialogPersonIcCard,
                        phone: this.dialogPersonPhone,
                        boxNumber:this.dialogPersonBoxNumber,
                        type: this.dialogPersonType,
                        description: this.dialogPersonDescription,
                        personGroupId: this.personGroupId,
                        fileName: this.dialogPersonFileName,
                    }).then((res) => {
                        if (res.data.code === 1) {
                            this.clearData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                            this.dialogPersonFileUrl = ''
                            this.dialogPersonFileName = ''
                        }
                        this.getPersonList(this.page, this.personGroupId)
                        this.addPersonDialogVisible = false
                    }).catch((error) => {
                        this.$message({
                            type: 'error',
                            message: '请求服务器失败!'
                        });
                        this.dialogPersonFileUrl = ''
                        this.dialogPersonFileName = ''
                    })
                })

            },
            updatePerson() {
                setTimeout((res) => {
                    axios.post('/doorPerson/update', {
                        id: this.dialogPersonId,
                        name: this.dialogPersonName,
                        idCard: this.dialogPersonIdCard,
                        icCard: this.dialogPersonIcCard,
                        type: this.dialogPersonType,
                        phone: this.dialogPersonPhone,
                        description: this.dialogPersonDescription,
                        personGroupId: this.personGroupId,
                        fileName: this.dialogPersonFileName
                    }).then((res) => {
                        if (res.data.code === 1) {
                            this.clearData()
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                        this.getPersonList(this.page, this.personGroupId)
                        this.updatePersonDialogVisible = false
                    })
                })
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },
            handleAvatarSuccess(res, file) {
                if (res.code === 1) {
                    this.dialogPersonFileName = res.data.fileName
                    this.dialogPersonFileUrl = URL.createObjectURL(file.raw);
                    return
                }
                this.dialogPersonFileName = ''
                this.dialogPersonFileUrl = ''
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            getPersonType(type) {
                if (type == 0) {
                    return '大众型'
                }
                if (type == 1) {
                    return '私有型'
                }
                if (type == 2) {
                    return '管理员'
                }

            },
            handleClose(done) {
                this.clearData()
                done()
            },
            deleteSelectPerson() {
                this.$confirm('此操作将永久删除当前选中人员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    setTimeout(() => {
                        var count = 0
                        for (var i = 0; i < this.selectPersonArray.length; i++) {
                            var person = this.selectPersonArray[i]
                            axios.get('/doorPerson/delete?id=' + person.id)
                                .then((res) => {
                                    count += 1
                                    if (count >= this.selectPersonArray.length) {
                                        this.$message({
                                            type: 'success',
                                            message: '删除执行完成!'
                                        });
                                        this.refreshPerson()
                                    }
                                })
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            updateSelectPersonToGroup() {
                this.selectUpdatePersonGroupDialogVisible = false
                setTimeout(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '请稍后......',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    var count = 0
                    for (var i = 0; i < this.selectPersonArray.length; i++) {
                        var person = this.selectPersonArray[i]
                        axios.post('/doorPerson/update', {
                            id: person.id,
                            name: person.name,
                            idCard: person.idCard,
                            icCard: person.icCard,
                            type: person.type,
                            phone: person.phone,
                            fileName: person.fileName,
                            personGroupId: this.personGroupId,
                        })
                            .then((res) => {
                                count += 1
                                if (count >= this.selectPersonArray.length) {
                                    loading.close()
                                    this.refreshPerson()()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                            .catch((error) => {
                                count += 1
                                if (count >= this.selectPersonArray.length) {
                                    loading.close()
                                    this.refreshPerson()()
                                    this.$message({
                                        type: 'success',
                                        message: '执行完成!',
                                    })
                                }
                            })
                    }
                })
            },
            refreshPerson() {
                this.getPersonGroupList()
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            importPerson(res, file) {
                this.importPersonDialogVisible = false
                if (res.code === 1) {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
                this.refreshPerson()

            },
            importPhoto(res, file) {
                this.importPersonDialogVisible = false
                this.refreshPerson()
                if (res.code === 1) {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }

            },
            exportPersonInfo() {
                axios.get('/doorPerson/downloadPersonInfo?page=' + this.page + '&length=' + this.length+'&likeName='+this.likeModel+'&likeIdCard='+this.likeIdCardModel+'&doorPersonGroupId='+this.personGroupId, {
                    responseType: 'blob'
                })
                    .then((res) => {
                        let data = res.data
                        const link = document.createElement('a')
                        let blob = new Blob([data], {
                            type: 'application/vnd.ms-excel'
                        })
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        link.download = '人员信息.xls'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
            },
            downLoadPersonPhoto() {
                axios.get('/doorPerson/downloadPersonPhoto?page=' + this.page + '&length=' + this.length+'&likeName='+this.likeModel+'&likeIdCard='+this.likeIdCardModel+'&doorPersonGroupId='+this.personGroupId, {
                    responseType: 'blob'
                })
                    .then((res) => {
                        let data = res.data
                        const link = document.createElement('a')
                        let blob = new Blob([data], {
                            type: 'application/zip'
                        })
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        link.download = '人员图片.zip'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
            }
        }
    }
</script>

<style>
    .door_person-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_deviec-tabler {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 95%;
        margin: 20px;
        min-height: 100px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }


    .door_person-data-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        margin-bottom: 10px;
    }


    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
